import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableModule } from 'primeng/table';
import { DataTableModule, AccordionModule, TreeModule } from 'primeng/primeng';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';
import { ChipsModule } from 'primeng/chips';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SafePipe } from '@app/pipes/safe-pipe';
import { LinkifyPipe } from '@app/pipes/linkify-pipe.ts';
import { NewlinePipe } from '@app/pipes/new-line-pipe';
import { ExternalLinkPipe } from '@app/pipes/external-link-pipe';
import { TypeaheadModule } from 'ngx-bootstrap';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { Pagination } from '@common/pagination/pagination.component';
import { Progress } from '@common/progress/progress.component';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ProjectCard } from '@common/project-card/project-card.component';
import { GroupCard } from '@common/group-card/group-card.component';
import { LocationAutocomplete } from '@common/location-autocomplete/location-autocomplete.component';
import { BsDropdownModule } from 'ngx-bootstrap';
import { NgxUploaderModule } from 'ngx-uploader';
import { Uploader } from './file-uploader/uploader.component';
import { AddressAutocomplete } from './address-autocomplete/address-autocomplete.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { CurrencyMaskConfig, CURRENCY_MASK_CONFIG } from "ng2-currency-mask/src/currency-mask.config";
import { TooltipModule } from 'primeng/tooltip';
import { OnlyNumbers } from './only-numbers/only-numbers.directive';
import { StripeComponent } from './stripe/stripe.component'
import { CreditCardComponent } from './credit-card/credit-card.component';
import { UploaderMultiple } from './file-uploader-multiple/uploader-multiple.component';
import { CarouselModule } from 'primeng/carousel';
import { CalendarModule } from 'primeng/calendar';
import { ExtendProjectComponent } from './extend-project/extend-project.component';
import { CancelProjectModalComponent } from '@common/modals/cancel-project/cancel-project-modal';
import { ImpactStatementComponent } from './impact-statement/impact-statement.component';
import { ImpactStatementModalComponent } from '@common/impact-statement-modal/impact-statement-modal.component';
import { CreateEmployeesGroupModalComponent } from './create-employees-group-modal/create-employees-group-modal.component';
import { CreateSubGroupModalComponent } from './create-subgroup-modal/create-subgroup-modal.component';
import { AddUserSubGroupModalComponent } from './add-user-subgroup-modal/add-user-subgroup-modal.component';
import { AddEmployeeBusinessModalComponent } from './add-employee-business-modal/add-employee-business-modal.component';
import { ProjectCompleteModal } from './project-complete-modal/project-complete-modal.component';
import { BusinessSubscriptionEditComponent } from './subscription-edit/subscription-edit.component'
import { BusinessSubscriptionComponent } from './subscription/subscription.component'
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { UploadCsvModalComponent } from './upload-csv-modal/upload-csv-modal.component';
import { CreateGroupModalComponent } from './create-group-modal/create-group-modal.component';
import { MessageModalComponent } from './message-modal/message-modal.component';
import {TreeTableModule} from 'primeng/treetable';
import { TransferFundsModalComponent } from '@common/transfer-funds-modal/transfer-funds-modal.component'
import { TransferEmployeesModalComponent } from '@common/transfer-employees-modal/transfer-employees-modal.component'
import { MultiSelectModule } from 'primeng/multiselect';
import { TabOrderDirective } from './tab-order/tab-order.directive'
import { DirectDebitComponent } from './direct-debit/direct-debit.component'
import { DirectDebitCompleteComponent } from './direct-debit-complete/direct-debit-complete.component' 
import { RecaptchaModule } from 'ng-recaptcha';

export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "right",
  allowNegative: false,
  decimal: ".",
  precision: 0,
  prefix: "£",
  suffix: "",
  thousands: ","
};

@NgModule({
  declarations: [
    SafePipe,
    NewlinePipe,
    ExternalLinkPipe,
    LinkifyPipe,
    Pagination,
    Progress,
    ProjectCard,
    LocationAutocomplete,
    Uploader,
    AddressAutocomplete,
    OnlyNumbers,
    StripeComponent,
    CreditCardComponent,
    UploaderMultiple,
    ExtendProjectComponent,
    CancelProjectModalComponent,
    ImpactStatementComponent,
    ImpactStatementModalComponent,
    CreateEmployeesGroupModalComponent,
    CreateSubGroupModalComponent,
    AddUserSubGroupModalComponent,
    AddEmployeeBusinessModalComponent,
    ProjectCompleteModal,
    BusinessSubscriptionEditComponent,
    BusinessSubscriptionComponent,
    UploadCsvModalComponent,
    GroupCard,
    CreateGroupModalComponent,
    MessageModalComponent,
    TransferFundsModalComponent,
    TransferEmployeesModalComponent,
    TabOrderDirective,
    DirectDebitComponent,
    DirectDebitCompleteComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    DataTableModule,
    OverlayPanelModule,
    ButtonModule,
    StepsModule,
    PopoverModule.forRoot(),
    TabsModule.forRoot(),
    ToastModule,
    ModalModule,
    CurrencyMaskModule,
    TypeaheadModule,
    PaginatorModule,
    ProgressBarModule,
    ScrollPanelModule,
    BsDropdownModule,
    NgxUploaderModule,
    AutoCompleteModule,
    TooltipModule,
    ReactiveFormsModule,
    FormsModule,
    CarouselModule,
    CalendarModule,
    ChipsModule,
    AccordionModule,
    TreeModule,
    SlickCarouselModule,
    MultiSelectModule,
    RecaptchaModule
  ],
  exports: [
    TableModule,
    DataTableModule,
    OverlayPanelModule,
    ButtonModule,
    PopoverModule,
    StepsModule,
    TabsModule,
    CurrencyMaskModule,
    ToastModule,
    ModalModule,
    SafePipe,
    NewlinePipe,
    ExternalLinkPipe,
    LinkifyPipe,
    TypeaheadModule,
    PaginatorModule,
    Pagination,
    ProgressBarModule,
    Progress,
    ScrollPanelModule,
    ProjectCard,
    LocationAutocomplete,
    NgxUploaderModule,
    Uploader,
    AddressAutocomplete,
    TooltipModule,
    OnlyNumbers,
    AutoCompleteModule,
    StripeComponent,
    ReactiveFormsModule,
    FormsModule,
    CreditCardComponent,
    UploaderMultiple,
    CarouselModule,
    CalendarModule,
    ExtendProjectComponent,
    CancelProjectModalComponent,
    ImpactStatementModalComponent,
    ImpactStatementComponent,
    ChipsModule,
    AccordionModule,
    TreeModule,
    BsDropdownModule,
    BusinessSubscriptionEditComponent,
    BusinessSubscriptionComponent, 
    SlickCarouselModule,
    GroupCard,
    TreeTableModule,
    MultiSelectModule,
    TabOrderDirective,
    DirectDebitComponent

  ],
  entryComponents: [
    CancelProjectModalComponent,
    ImpactStatementComponent,
    CreateEmployeesGroupModalComponent,
    CreateSubGroupModalComponent,
    AddUserSubGroupModalComponent,
    AddEmployeeBusinessModalComponent,
    ProjectCompleteModal,
    CreateGroupModalComponent,
    UploadCsvModalComponent,
    MessageModalComponent,
    TransferFundsModalComponent,
    TransferEmployeesModalComponent
  ]
})
export class IICCommonModule { }
