import { AppRoutingModule } from './app-routing';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app/app.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { FooterComponent } from './footer/footer.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { IICCommonModule, CustomCurrencyMaskConfig } from '@common/iic.common.module'
import { EmailVerificationComponent } from '@appmodule/email-activation/email-verification.component';
import { EmailInviteComponent } from '@appmodule/email-invite/email-invite.component';
import { LinkStripeComponent } from './link-stripe/link-stripe.component';
import { MessageService } from 'primeng/api';
import { CharityWebService, UserWebService, AdminWebService, GiftWebService, InvitationWebService, CompanyWebService, ExploreWebService, UploadWebService, WalletWebService, SubscriptionWebService, DonationWebService, GroupWebService, MessageWebService, OrganisationWebService } from '@providers/web.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CharityService } from '../charity/charity.service';
import { CompanyService } from '../business/business.service';
import { IndividualService } from '../individual/individual.service';
import { AppService } from '../app/app.service';
import { AuthGuard } from '@app/providers/auth-guard.provider';
import { AdminAuthGuard } from '@app/providers/admin-auth-guard.provider';
import { CURRENCY_MASK_CONFIG } from 'ng2-currency-mask/src/currency-mask.config';
import { ThankYouModalComponent } from '../charity/project/donate-modals/thank-you-modal/thank-you-modal.component';
import { IndividualDonateFundsComponent } from '@charity/project/donate-modals/funds-modal/individual-donate-modal/individual-donate-modal.component';
import { ErrorHandler } from '@angular/core';
import { GlobalErrorHandler } from '@app/modules/app/app.error.handler';
import { AuthGuardNoRedirect } from '@app/providers/auth-guard-no-redirect.provider';
import { ShareModule } from '@ngx-share/core';
import { HttpClientModule } from '@angular/common/http';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ActivationComponent } from '@app/modules/register/activation/activation.component';
// import { GroupProfileComponent } from '../group/profile/profile.component'
import { GroupService } from '../group/group.service';
import { MessageModalComponent } from '../common/message-modal/message-modal.component';
import { RecaptchaModule } from 'ng-recaptcha';

@NgModule({
  declarations: [
    AppComponent,
    TopBarComponent,
    FooterComponent,
    EmailVerificationComponent,
    EmailInviteComponent,
    LinkStripeComponent,
    ThankYouModalComponent,
    IndividualDonateFundsComponent,
    ActivationComponent,
    // GroupProfileComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BsDropdownModule.forRoot(),
    BrowserAnimationsModule,
    HttpModule,
    HttpClientModule,
    IICCommonModule,
    ShareModule.forRoot(),
    RecaptchaModule,
    SlickCarouselModule
  ],
  entryComponents:[ThankYouModalComponent, IndividualDonateFundsComponent, MessageModalComponent],
  providers: [
    MessageService,
    UserWebService,
    CharityWebService,
    BsModalService,
    AdminWebService,
    InvitationWebService,
    CompanyWebService,
    ExploreWebService,
    UploadWebService,
    WalletWebService,
    SubscriptionWebService,
    DonationWebService,
    CharityService,
    CompanyService,
    IndividualService,
    AppService,
    GiftWebService,
    AuthGuard, 
    AdminAuthGuard,
    AuthGuardNoRedirect,
    GroupWebService,
    GroupService,
    MessageWebService,
    OrganisationWebService,
    {
      provide: CURRENCY_MASK_CONFIG,
      useValue: CustomCurrencyMaskConfig
    },
    {
      provide: ErrorHandler, 
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
